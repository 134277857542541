import {request} from "@/network/request";

export function getLeaseMsg(data){
    console.log(data)
    return request({
        params:data,
        method:'get',
        url:'/rentRoom/selectRentUserRoom'
    })
}
