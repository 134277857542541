<template>
    <div class="container d-flex flex-row" style="height: 750px">
        <v-card class="card" v-for="item in leaseMsgList">
            <v-row>
                <div class="content" style="margin-top: 30px">
                    <div class="rfont">
                        <p style="font-size: 20px;padding-left: 18px">出租方：</p>
                    </div>

                    <div>
                        <p style="font-size: 18px;margin-top: 3px">{{item.landlordName}}</p>
                    </div>
                </div>
            </v-row>
            <v-row>
                <div class="content">
                    <div class="rfont">
                        <p style="font-size: 20px;padding-left: 18px">承租方：</p>
                    </div>

                    <div>
                        <p style="font-size: 18px;margin-top: 3px">{{item.rentUserName}}</p>
                    </div>
                </div>
            </v-row>
            <v-row>
                <div class="content">
                    <div class="rfont">
                        <p style="font-size: 20px;">租赁时间：</p>
                    </div>

                    <div>
                        <p style="font-size: 18px;margin-top: 3px">{{item.startDate}}</p>
                    </div>
                </div>
            </v-row>
            <v-row>
                <div class="content">
                    <div class="rfont">
                        <p style="font-size: 20px;">到期时间：</p>
                    </div>

                    <div>
                        <p style="font-size: 18px;margin-top: 3px">{{item.endDate}}</p>
                    </div>
                </div>
            </v-row>
            <v-row>
                <div class="content">
                    <div class="rfont">
                        <p style="font-size: 20px;">房屋地址：</p>
                    </div>
                    <div>
                        <p style="font-size: 18px;margin-top: 3px">{{item.address}}</p>
                    </div>
                </div>
            </v-row>
            <v-row>
                <div class="content">
                    <div class="rfont">
                        <p style="font-size: 20px;">房屋用途：</p>
                    </div>

                    <div>
                        <p style="font-size: 18px;margin-top: 3px">{{item.roomTypeName}}</p>
                    </div>
                </div>
            </v-row>
            <div class="button">
                <el-button type="primary" style="width: 300px" plain @click="input(item)">录入日常信息</el-button>
            </div>

        </v-card>
    </div>
</template>

<script>

    import {getLeaseMsg} from "@/network/Details/general_page/leaseManage";

    export default {
        name: "LeaseManage",
        created() {
            getLeaseMsg({
                userID: this.userId
            }).then(res => {
                if (res.status === 200) {
                    this.leaseMsgList = res.data
                }
            })
        },
        data() {
            return {
                userId: window.localStorage.getItem("userId"),
                leaseMsgList: [],
                leaseMsg: {
                    address: "string",
                    endDate: "2021-08-12T12:04:24.914Z",
                    landlordName: "string",
                    rentUserName: "string",
                    roomTypeName: "string",
                    startDate: "2021-08-12T12:04:24.914Z"
                }
            }
        },
        methods: {
            input(room) {
                let url = this.$router.resolve({
                    path: "/DailyManage",
                    query: {
                        roomId: room.roomId
                    }
                })
                window.open(url.href, "_blank")
            },

        }

    }
</script>

<style scoped lang="less">
    .card {
        height: 400px;
        width: 450px;
        margin: 10px 15px
    }

    .content {
        display: flex;
        //border: 1px solid red;
        height: 50px;
        width: 450px;
        margin: 0px 30px 0px 50px;
        padding-top: 6px;

    }

    .button {
        margin-top: 20px;
        margin-left: 80px;
    }

    .rfont {
        //border: 2px solid red;
        width: 110px;
    }
</style>
